$breakpoint-mobile: 568px;
$breakpoint-tabs: 991px;
$breakpoint-smallLaptops: 1024px;
$primary--color--1: var(--Primary---1, #213c59);

.work-type-section {
  padding-bottom: 6rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem; // Add gap for consistent spacing between cards

  @media (max-width: $breakpoint-mobile) {
    padding-bottom: 1rem;
  }

  .vehicle-container {
    flex: 1 1 calc(50% - 1rem);
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    @media (max-width: $breakpoint-mobile) {
      flex: 1 1 100%;
    }

    .card-wrap {
      border-radius: 20px !important;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.02),
        0px 2px 4px 0px rgba(0, 0, 0, 0.04);
      border: none !important;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      @media (max-width: $breakpoint-mobile) {
        min-height: 275px !important;
      }

      .card-body {
        background: #f3f4f7;
        padding: 0rem !important;
        border-radius: 20px !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .content-wrap {
          max-width: 500px;
          padding-top: 2rem;
          padding-left: 45px;
          flex-grow: 1;

          @media (max-width: $breakpoint-smallLaptops) {
            padding-left: 0px;
            padding: 20px;
          }

          @media (max-width: $breakpoint-tabs) {
            padding-left: 0px;
            padding: 20px;
          }

          .card-title {
            text-align: left;
            color: $primary--color--1;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            letter-spacing: -0.4px;
            border-bottom: 5px solid #b08e5e;
            max-width: 200px;
            padding-bottom: 10px;
            margin-bottom: 16px;
            @media (max-width: $breakpoint-mobile) {
              font-size: 16px;
            }
          }

          .card-text {
            color: var(--Primary---1, #213c59);
            font-size: 20px;
            text-align: left;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.5px;
            padding-bottom: 3rem;

            @media (max-width: $breakpoint-tabs) {
              padding-bottom: 0px;
            }

            @media (max-width: $breakpoint-mobile) {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
