$breakpoint-tab: 768px;
.card-wrap {
  border: none !important;
  min-height: 350px;
  .tag-title {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(33, 60, 89, 0.3);
    p {
      border-radius: 4px;
      background: var(--Secondary, #b08e5e);
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.04),
        0px 4px 10px 0px rgba(0, 0, 0, 0.08);
      min-width: 175px;
      padding: 10px;
      color: var(--White, #fffefe);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: -0.4px;
      margin: 0px;
      text-align: center;
    }
  }
  .img-container {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    @media (max-width: $breakpoint-tab) {
      padding-bottom: 110%;
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 100%;
      width: auto;
      transform: translate(-50%, -50%);
    }
  }
}
