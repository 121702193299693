.driver-form {
  background: #345f8a;
  width: 80%;
  padding: 20px;
  border-radius: 10px;
  h2 {
    color: var(--secondary);
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -1.2px;
    padding-bottom: 1rem;
    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
}
@media (max-width: 768px) {
  .driver-form {
    width: 95%; /* Adjust this value as needed */
  }
}