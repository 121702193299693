@font-face {
  font-family: 'PlusJakartaSans';
  src: url('./assets/fonts/PlusJakartaSans/PlusJakartaSans-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'PlusJakartaSans';
  src: url('./assets/fonts/PlusJakartaSans/PlusJakartaSans-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'PlusJakartaSans';
  src: url('./assets/fonts/PlusJakartaSans/PlusJakartaSans-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'PlusJakartaSans';
  src: url('./assets/fonts/PlusJakartaSans/PlusJakartaSans-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'PlusJakartaSans';
  src: url('./assets/fonts/PlusJakartaSans/PlusJakartaSans-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'PlusJakartaSans';
  src: url('./assets/fonts/PlusJakartaSans/PlusJakartaSans-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: 'DMSans';
  src: url('./assets/fonts/DMSans/DMSans-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'DMSans';
  src: url('./assets/fonts/DMSans/DMSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

body {
  margin: 0;
  font-family: 'PlusJakartaSans' !important;
}

.font-dm-sans {
  font-family: 'DMSans' !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-1: #213c59;
  --primary: #345f8a;
  --secondary: #b08e5e;
  --secondary-1: #eeeadb;
  --gray-100: #e2e4eb;
  --gray-color-200: #ccd0dc;
}

/* Start Header CSS */
.header-primary {
  z-index: 1000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  background: var(--secondary-1) !important;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.04),
    0px 4px 10px 0px rgba(0, 0, 0, 0.08);
}
.header-primary a.nav-link.text-start {
  padding: 0rem 2rem;
}
.header-primary .nav-link {
  text-align: justify;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 150%;
  letter-spacing: -0.32px;
}
.header-primary .nav-link.active {
  color: var(--secondary) !important;
  font-weight: 800 !important;
}
.header-primary .primary-btn {
  border-radius: 4px;
  background: var(--secondary);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.04),
    0px 4px 10px 0px rgba(0, 0, 0, 0.08);
  color: var(--White, #fffefe);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  width: 136px;
  padding: 10px;
  border: none;
  outline: none;
  text-decoration: none;
}
@media (max-width: 991px) {
  .header-primary a.nav-link.text-start {
    padding: 1rem 0rem;
  }
  .header-primary .primary-btn {
    display: flex;
    margin-bottom: 1rem;
    justify-content: center;
  }
}
/* End Header CSS */
/* Start Footer CSS */
.footer-wrapper {
  background: var(--secondary-1);
  min-height: 385px;
}
.footer-wrapper .divider {
  background: var(--secondary) !important;
  height: 2px;
}
.footer-wrapper .footer-list ul {
  padding-bottom: 3rem;
  padding-left: 0rem;
  text-align: left;
}
.footer-wrapper .footer-list ul li:first-child {
  color: var(--primary-1);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.4px;
  padding-bottom: 2rem;
  padding-top: 1rem;
}
.footer-wrapper .footer-list ul li {
  padding-bottom: 1.3rem;
  @media (max-width: 768px) {
    padding-bottom: 0.8rem;
  }
}
.footer-wrapper .footer-list ul li a {
  color: var(--primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.32px;
  text-decoration: none;
}
.footer-wrapper .footer-bottom ul {
  margin-bottom: 0rem;
}
.footer-wrapper .footer-bottom p {
  margin-bottom: 0rem;
  color: var(--primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.32px;
}
.footer-wrapper .footer-bottom ul li {
  color: var(--primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.32px;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.footer-wrapper .footer-bottom ul li:first-child {
  padding-right: 4rem;
}
@media (max-width: 991px) {
  .footer-wrapper .footer-list ul {
    padding-bottom: 0rem;
  }
  .footer-wrapper .footer-list ul li:first-child {
    padding-top: 0rem;
    padding-bottom: 0.5rem;
  }
  .footer-wrapper .footer-bottom ul {
    padding-left: 0px;
    padding-top: 1rem;
  }
}
/* End Footer CSS */

.remove-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.bg-primary1 {
  background-color: #213c59;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}
#contact-form-button {
  background-color: #213c59;
  color: #fff;
  border-radius: 4px;
  padding: 10px 30px;
  border: none;
  margin-top: 2.5%;
}

.form-container {
  margin-top: 2%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.toast-parent {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  @media (max-width: 410px) {
    right: 4px;
    width: 90%;
  }
}
