// CSS
$breakpoint-laptops: 1024px;
$breakpoint-tab: 991px;
$breakpoint-mobile: 568px;
$breakpoint-smallMobile: 320px;
$white--color: var(--white, #fff);
$gray--color: var(--Gray-gray-200, #ccd0dc);
$secondary--color: var(--Secondary, #b08e5e);
$primary--color--1: var(--Primary---1, #213c59);
.showcase {
  width: 100%;
  min-height: 75vh;
  position: relative;
  color: white;
  text-align: center;
  img {
    object-fit: cover;
  }
  @media (max-width: $breakpoint-tab) {
    min-height: 100vh;
  }
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }
  h2 {
    font-size: 3.75em;
    color: $secondary--color;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -1.2px;
    @media (max-width: $breakpoint-tab) {
      font-size: 2.5rem;
      padding: 0rem 2rem;
    }
    @media (max-width: $breakpoint-mobile) {
      font-size: 2rem;
      padding: 0rem 1rem;
    }
  }
  .overlay {
    width: 100%;
    min-height: 75vh;
    background: linear-gradient(
      262deg,
      rgb(52 95 138) -32.74%,
      rgb(14 25 36 / 94%) 46.59%
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: $breakpoint-tab) {
      min-height: 100vh;
    }
  }
  p {
    margin-top: 8px;
    max-width: 1075px;
    text-align: left;
    color: $gray--color;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.5px;
    padding: 0rem 2rem;
    @media (max-width: $breakpoint-laptops) {
      max-width: 950px;
      padding: 0rem 2rem;
      text-align: justify;
    }
    @media (max-width: $breakpoint-mobile) {
      max-width: 700px;
      text-align: justify;
      font-size: 1rem;
      padding: 1rem;
    }
  }
}

.section-work-type {
  color: $primary--color--1;
  text-align: center;
  font-size: 3.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -1.2px;
  margin-bottom: 2rem;
  @media (max-width: $breakpoint-mobile) {
    font-size: 2rem;
  }
}

.section-service {
  color: $secondary--color;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.4px;
  text-transform: uppercase;
  margin-top: 100px !important;
}
