.full-width {
  height: 100%;
}

.image-wrapper {
  height: 105vh;
}

.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover; // Ensures the image covers the entire container
}

.driver-form-container {
  position: absolute;
  z-index: 999;
  top: 35%;
  left: 23%;
}

@media (max-width: 972px) {
  .image-wrapper {
    height: 120vh;
  }
  .driver-form-container {
    top: 30%;
    left: 1%;
  }
}

.form-padding {
  top: 63%;
}
