/* formInput.scss */
.form-group-container {
  display: 'flex';
  flex-direction: 'column';
  #form-label {
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #e7e7e9;
    margin-bottom: 0.1rem;
    @media (max-width: 760px) {
      font-size: 0.8rem;
    }

    @media (min-width: 760px) and (max-width: 991px) {
      font-size: 1.1rem;
    }
  }
  #custom-input {
    background: transparent;
    min-height: 40px;
    border-radius: 4px;
    border: 1px solid var(--gray-100);
    padding: 12px 20px;
    color: var(--gray-color-200);
    font-size: 1rem;
    @media (max-width: 991px) {
      height: 35px;
    }
    &::placeholder {
      color: var(--gray-color-200);
    }

    &:focus {
      box-shadow: none;
      border: 1px solid var(--secondary);
      outline: none; /* Ensure no default outline */
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
}

/* Ensuring no background when input has a value */
#custom-input:not(:placeholder-shown) {
  background: transparent;
}
