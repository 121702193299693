$mobile: 568px;
$tab: 991px;
$gray-color-200: var(--Gray-gray-200, #ccd0dc);
$primary-color: var(--Primary, #345f8a);
$secondary-color: var(--Secondary, #b08e5e);
.instant-container {
  h1 {
    color: $secondary-color;
    text-align: center;
    font-size: 3.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -1.2px;
    padding-top: 3%;
    @media (min-width: $mobile) and (max-width: 1040px) {
      font-size: 2.5rem;
      padding: 0.5rem;
      padding-top: 2rem;
    }
    @media (max-width: $mobile) {
      font-size: 1.5rem;
      padding: 0.5rem;
      padding-top: 2rem;
    }
  }
  p {
    color: var(--white, #fff);
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -0.68px;
    @media (max-width: $mobile) {
      font-size: 1rem;
    }
  }

  .get-quote-form {
    max-width: 70%;
    margin: auto;
    border-radius: 10px;
    background: var(--Primary, #345f8a);
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.04),
      0px 8px 32px 0px rgba(0, 0, 0, 0.08);
    padding: 3.125rem 2.125rem 1.688rem 2.125rem;
    @media (max-width: $tab) {
      max-width: 90%;
    }
    .form-label {
      display: none;
    }
    p {
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-align: left;
      @media (max-width: 760px) {
        font-size: 0.8rem;
      }
      @media (min-width: 760px) and (max-width: $tab) {
        font-size: 1.1rem;
      }
    }
    .form-control {
      background: transparent;
      min-height: 40px;
      border-radius: 4px;
      border: 1px solid var(--Gray-gray-100, #e2e4eb);
      padding: 12px 20px;
      color: $gray-color-200;
      font-size: 1rem;
      @media (max-width: $tab) {
        height: 35px;
      }
      &::placeholder {
        color: $gray-color-200;
      }
      &:focus {
        box-shadow: none;
        border: 1px solid var(--Secondary, #b08e5e);
      }
    }
    .joint {
      font-size: 1rem;
      color: $gray-color-200;
      @media (max-width: $tab) {
        padding: 1rem 0rem;
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .card {
      width: 100%;
      background: transparent;
      border-radius: 7.521px;
      border: 0.94px solid var(--White, #fffefe);
      padding: 1rem;
      margin-right: 1rem;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;
      cursor: pointer;
    }

    .card span {
      color: var(--White, #fffefe);
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      text-transform: capitalize;
    }

    .card img {
      width: 40px;
      margin-bottom: 1rem;
    }

    .card:hover {
      background-color: var(--Secondary, #b08e5e);
      border: none;
    }

    @media (max-width: 768px) {
      .card span {
        font-size: 10px;
      }

      .card img {
        width: 33px;
      }
    }

    @media (max-width: $tab) {
      .mt-sml-4 {
        margin-top: 2rem;
      }
    }
  }

  .btn-alignment {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    .search-btn {
      border-radius: 4px;
      background: var(--Primary---1, #213c59);
      display: flex;
      width: 140px;
      height: 60px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      color: var(--Foundation-White, #fff);
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.5px;
      outline: none;
      border: none;
    }
  }
}

.instant-footer {
  margin-top: 6rem;
  background: linear-gradient(
    261.6deg,
    rgba(52, 95, 138, 0.7) -32.74%,
    rgba(14, 25, 36, 0.7) 46.59%
  );

  padding: 5rem;
  p {
    width: 70%;
    color: $gray-color-200;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    letter-spacing: 0.5px;
    margin: auto;
    @media (min-width: 760px) and (max-width: 991px) {
      text-align: justify;
      font-size: 17px;
    }
    @media (max-width: $mobile) {
      text-align: justify;
      width: 94%;
      font-size: 14px;
    }
  }
  @media (max-width: $mobile) {
    padding: 2rem;
    text-align: justify;
  }
}
