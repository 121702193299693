.contact-form-container {
  text-align: center;
  align-items: center;
  margin-top: 2%;
  h1 {
    color: var(--secondary);
    font-size: 3.75rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -1.2px;
    @media (max-width: 768px) {
      font-size: 2.5rem;
      padding: 0.5rem;
    }
  }
  p {
    color: #fff;
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.5px;
    padding: 0px 3px;

    @media (max-width: 768px) {
      font-size: 1.1rem;
    }

    @media (min-width: 769px) and (max-width: 1080px) {
      font-size: 1.4rem;
    }
  }
}

@media (max-width: 768px) {
  .contact-form-container {
    padding: 0px 2px 0px 2px;
  }
}
