.form-inner-container {
  background: #345f8a;
  width: 60%;
  padding: 20px;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .form-inner-container {
    width: 95%; /* Adjust this value as needed */
  }
}
